import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function getCurrentAuth() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/users/me`,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.username) {
        dispatch({ type: ActionTypes.AUTH_USER, payload: { user: response.data } });
        // This second dispatch populates the dashboardUseCelsius value in settings
        if (response.data?.useCelsius != null) {
          dispatch({ type: ActionTypes.SET_DASHBOARD_USE_CELSIUS, payload: response.data });
        }
      }
    } catch (error) {
      dispatch({ type: ActionTypes.AUTH_ERROR, payload: error?.response?.status });
    }
  };
}

export function signinUser(params) {
  const { username, password, twoFactorCode } = params;

  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/users/authenticate`,
        data: { username, password, twoFactorCode },
      });

      if (response.status === 202) {
        dispatch({ type: ActionTypes.AUTH_NEED_2FA, payload: { user: response.data } });
      } else {
        localStorage.setItem(TOKEN_STRING, response.data.token);
        dispatch({ type: ActionTypes.AUTH_USER, payload: { user: response.data, token: response.data.token } });
        // This second dispatch populates the dashboardUseCelsius value in settings
        if (response.data?.useCelsius != null) {
          dispatch({ type: ActionTypes.SET_DASHBOARD_USE_CELSIUS, payload: response.data });
        }
      }
    } catch (error) {
      dispatch({ type: ActionTypes.AUTH_ERROR, payload: error?.response?.status });
    }
  };
}

export function signoutUser() {
  return (dispatch) => {
    localStorage.removeItem(TOKEN_STRING);
    dispatch({ type: ActionTypes.DEAUTH_USER });
  };
}

export function signoutUserAuto() {
  localStorage.removeItem(TOKEN_STRING);
  return (dispatch) => {
    dispatch({ type: ActionTypes.DEAUTH_USER_AUTO });
  };
}

// Parse token from base64 encoding
function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

// Function to fetch the JWT token from local storage and check if it is expired or not.
export function jwtTokenIsExpired() {
  const token = localStorage.getItem(TOKEN_STRING);
  if (token) {
    const decodedJwt = parseJwt(token);
    // If our expiration date is great than the current date e.g hasn't happened yet,
    // then our token isn't expired.
    if ((decodedJwt.exp * 1000) > Date.now()) {
      return false;
    }
  }
  return true;
}
