import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function fetchSettings() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/usersettings`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_SETTINGS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.SETTINGS_ERROR, payload: error });
    }
  };
}

export function updateSettings(data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/usersettings/update`,
        headers: { Authorization: `Bearer ${token}` },
        data,

      });
      dispatch({ type: ActionTypes.UPDATE_SETTINGS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.SETTINGS_ERROR, payload: error });
    }
  };
}

export function fetchGunModels() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/gunModels`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_GUN_MODELS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.GUN_MODELS_ERROR, payload: error });
    }
  };
}

// IMPORTANT: For the dashboard useCelsius is pulled from the users route
// but is then stored in the settings reducer which is a little confusing.
// We can't use the auth reducer where the 'user' lives without causing re-rendering issues and useState hook incontinuity.
// So we use the settings reducer and just pull the value from the user and store it in settings->dashboardUseCelsius
export function setDashboardUnits(useCelsius) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/users/UseCelsius/${useCelsius}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: ActionTypes.SET_DASHBOARD_USE_CELSIUS, payload: response.data });
      return response.data?.useCelsius;
    } catch (error) {
      dispatch({ type: ActionTypes.SET_DASHBOARD_USE_CELSIUS_ERROR, payload: error?.response?.status });
      return error?.response;
    }
  };
}

// export function fetchGunModels() {
//   return async (dispatch) => {
//     try {
//       const token = localStorage.getItem(TOKEN_STRING);

//       const response = await axios({
//         method: 'get',
//         url: `${ROOT_URL}/gunModels`,
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       dispatch({ type: ActionTypes.FETCH_SETTINGS_SUCCESS, payload: response.data });
//     } catch (error) {
//       dispatch({ type: ActionTypes.SETTINGS_ERROR, payload: error });
//     }
//   };
// }
