import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

// axios.interceptors.request.use((req) => {
//   console.log(`${req.method} ${req.url}`);
//   // Important: request interceptors **must** return the request.
//   return req;
// });

// axios.interceptors.response.use((res) => {
//   console.log(res.data.json);
//   // Important: response interceptors **must** return the response.
//   return res;
// });

export function fetchAllHydrants() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/hydrants/`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_ALL_HYDRANTS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_HYDRANT_ERROR, payload: error });
    }
  };
}

export function fetchHydrant(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/hydrants/${id}`,
        headers: { Authorization: `Bearer ${token}` },

      });
      dispatch({ type: ActionTypes.FETCH_HYDRANT_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_HYDRANT_ERROR, payload: error });
    }
  };
}

export function selectHydrant(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.SELECT_HYDRANT, payload: id });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_HYDRANT_ERROR, payload: error });
    }
  };
}

export function bulkSetHydrantIOValues(data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/trackeriovalues`,
        data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      // dispatch({ type: ActionTypes.FETCH_HYDRANT_IO_SUCCESS, payload: { ...response.data, hydrantId } });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_HYDRANT_ERROR, payload: error });
    }
  };
}

export function setHydrantIOValue(id, hydrantId, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/trackeriovalues/${id}/value`,
        data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.FETCH_HYDRANT_IO_SUCCESS, payload: { ...response.data, hydrantId } });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_HYDRANT_ERROR, payload: error });
    }
  };
}

export function fetchAllTrackerIOValues() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/trackeriovalues`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_TRACKER_IOVALUES_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_HYDRANT_ERROR, payload: error });
    }
  };
}

export function addHydrant(data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/hydrants/add`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.ADD_HYDRANT_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_HYDRANT_ERROR, payload: error });
      return error?.response;
    }
  };
}

export function deleteHydrant(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'delete',
        url: `${ROOT_URL}/hydrants/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.DELETE_HYDRANT_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_HYDRANT_ERROR, payload: error });
      return error?.response;
    }
  };
}

// Just pass in obj with properties (single depth) that I want to update
// {
//   isEnabled: 1,
// }
// NOTE: The reducer for this dispatch has been moved to the trails reducer
export function updateHydrant(id, data) {
  console.log('action: update hydrant success');
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/hydrants/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.UPDATE_HYDRANT_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_HYDRANT_ERROR, payload: error });
      return error?.response;
    }
  };
}

export function bulkUpdateHydrants(data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/hydrants`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      console.log(response);
      // dispatch({ type: ActionTypes.UPDATE_HYDRANT_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_HYDRANT_ERROR, payload: error });
      return error?.response;
    }
  };
}

export function fetchAllHydrantModels() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/HydrantModels/`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_ALL_HYDRANT_MODELS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_HYDRANT_ERROR, payload: error });
    }
  };
}

// id: hydrant id
// analogInput: integer representing analog input channel 1 or 2 (or undefined for klik_gen5 or non-analog)
// data:
// {
//   type: "wetbulb", "waterPressure", or "airPressure",
//   equipmentId: integer -4 (klik_gen3) or -5 (klik_gen5)
// }
// NOTE: This uses the trails reducer to be able to integrate response with AutoTrailIOValues
// it then returns the status of the response for error handling
export function addHydrantSensor(id, dataString, analogInput) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/autotrailiovalues/hydrant/${id}/${analogInput || ''}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: dataString,
      });
      dispatch({ type: ActionTypes.ADD_AUTO_TRAIL_IO_VALUE, payload: response });
      return response.status;
    } catch (error) {
      dispatch({ type: ActionTypes.AUTO_HYDRANT_ERROR, payload: error?.response?.status });
      return error?.response.status;
    }
  };
}

export function deleteHydrantSensor(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'delete',
        url: `${ROOT_URL}/autotrailiovalues/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.DELETE_AUTO_TRAIL_IO_VALUE, payload: response.data });
      return response.status;
    } catch (error) {
      dispatch({ type: ActionTypes.AUTO_HYDRANT_ERROR, payload: error?.response?.status });
      return error?.response.status;
    }
  };
}
