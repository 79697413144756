import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

// axios.interceptors.request.use((req) => {
//   console.log(`${req.method} ${req.url}`);
//   // Important: request interceptors **must** return the request.
//   return req;
// });

// axios.interceptors.response.use((res) => {
//   console.log(res.data.json);
//   // Important: response interceptors **must** return the response.
//   return res;
// });

export function fetchAllGuns() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/guns/`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_ALL_GUNS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GUNS_ERROR, payload: error });
    }
  };
}

export function fetchGun(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/guns/${id}`,
        headers: { Authorization: `Bearer ${token}` },

      });
      dispatch({ type: ActionTypes.FETCH_GUN_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GUNS_ERROR, payload: error });
    }
  };
}

export function updateGunIsAvailable(id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/guns/${id}/isAvailable`,
        headers: { Authorization: `Bearer ${token}` },
        data,

      });
      dispatch({ type: ActionTypes.UPDATE_GUN_AVAILABLE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GUNS_ERROR, payload: error });
    }
  };
}

export function addGun(data, gunModels) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const gunResponse = await axios({
        method: 'post',
        url: `${ROOT_URL}/guns/add`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.ADD_GUN_SUCCESS, payload: gunResponse.data });

      const gunModelResponses = [];
      for (let i = 0; i < gunModels.length; i++) {
        gunModelResponses.push(axios({
          method: 'post',
          url: `${ROOT_URL}/gunModels/add`,
          data: {
            gunId: gunResponse?.data?.id,
            stage: gunModels[i].stage,
            fixedFlowFactorOverride: gunModels[i].fixedFlowFactorOverride,
            cfmOverride: gunModels[i].cfmOverride,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }));
      }
      // Wait for all async promises to resolve then dispatch to reducer
      await Promise.all(gunModelResponses);
      for (let j = 0; j < gunModelResponses.length; j++) {
        dispatch({ type: ActionTypes.ADD_GUN_MODEL_SUCCESS, payload: gunModelResponses[j].data });
      }
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GUNS_ERROR, payload: error });
    }
  };
}

export function updateGun(id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/guns/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.UPDATE_GUN_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GUNS_ERROR, payload: error });
    }
  };
}

export function addGunModel(data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const gunResponse = await axios({
        method: 'post',
        url: `${ROOT_URL}/gunModels/add`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.ADD_GUN_MODEL_SUCCESS, payload: gunResponse.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GUNS_ERROR, payload: error });
    }
  };
}

export function deleteGunModel(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const gunResponse = await axios({
        method: 'delete',
        url: `${ROOT_URL}/gunModels/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.DELETE_GUN_MODEL_SUCCESS, payload: gunResponse.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GUNS_ERROR, payload: error });
    }
  };
}

export function updateGunModel(id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/gunModels/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.UPDATE_GUN_MODEL_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GUNS_ERROR, payload: error });
    }
  };
}
// export function addGunModel(data) {
//   return async (dispatch) => {
//     try {
//       const token = localStorage.getItem(TOKEN_STRING);

//       const response = await axios({
//         method: 'post',
//         url: `${ROOT_URL}/gunModels/add`,
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         data,
//       });
//       dispatch({ type: ActionTypes.ADD_GUN_MODEL_SUCCESS, payload: response.data });
//     } catch (error) {
//       dispatch({ type: ActionTypes.FETCH_GUNS_ERROR, payload: error });
//     }
//   };
// }
