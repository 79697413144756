import { ActionTypes } from '../actions/Shared';

const initialState = {
  trends: null,
  IOValues: {},
  snowmakingHours: null,
  snowmakingTrails: null,
  totalGallons: null,
  averageSei: null,
  datasets: null,
};

const TrendReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_TRENDS_SUCCESS:
      return { ...state, trends: action.payload };
    case ActionTypes.ADD_TREND_SUCCESS:
      return { ...state, trends: { ...state.trends, [action.payload.id]: action.payload } };
    case ActionTypes.DELETE_TREND_SUCCESS:
      return { ...state, trends: { ...Object.values(state.trends).filter((item) => item.id !== action.payload.id) } };
    case ActionTypes.ADD_TRENDIOVALUE_SUCCESS:
      return {
        ...state,
        trends: {
          ...state.trends,
          [action.payload.trendId]: {
            ...state.trends[action.payload.trendId],
            trendIOValues: {
              ...state.trends[action.payload.trendId].trendIOValues,
              [action.payload.ioValueId]: action.payload,
            },
          },
        },
      };
    case ActionTypes.DELETE_TRENDIOVALUE_SUCCESS:
      return {
        ...state,
        trends: {
          ...state.trends,
          [action.payload.trendId]: {
            ...state.trends[action.payload.trendId],
            trendIOValues: {
              ...Object.values(state.trends[action.payload.trendId].trendIOValues).filter((item) => item.id !== action.payload.id),
            },
          },
        },
      };
    case ActionTypes.FETCH_TREND_DATASET_SUCCESS:
      return {
        ...state,
        datasets: {
          ...state.datasets,
          [action.payload.ioValueId || `${action.payload.weatherId}_${action.payload.weatherMetric}`]: action.payload,
        },
      };
    case ActionTypes.FETCH_ALL_IOVALUES_SUCCESS:
      return {
        ...state,
        IOValues: {
          ...state.IOValues,
          ...action.payload,
        },
      };
    case ActionTypes.SET_IOVALUE_SUCCESS:
      return {
        ...state,
        IOValues: {
          ...state.IOValues,
          [action.payload.id]: action.payload,
        },
      };
    case ActionTypes.FETCH_SNOWMAKING_HOURS_SUCCESS:
      return { ...state, snowmakingHours: action.payload };
    case ActionTypes.FETCH_SNOWMAKING_TRAILS_SUCCESS:
      return { ...state, snowmakingTrails: action.payload };
    case ActionTypes.FETCH_TOTAL_GALLONS_SUCCESS:
      return {
        ...state,
        totalGallons: {
          ...state.totalGallons,
          [action.payload.id]: {
            ...action.payload,
          },
        },
      };
    case ActionTypes.FETCH_AVERAGE_SEI_SUCCESS:
      return { ...state, averageSei: action.payload };
    case ActionTypes.TREND_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default TrendReducer;
