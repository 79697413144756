import { ActionTypes } from '../actions/Shared';

const initialState = {
  trails: null,
  autotrails: null,
  autotrailsiovalues: null,
  trail: null,
  selectedTrailName: null,
  selectedAutoTrailName: null,
  hydrants: null,
  globalNotes: null,
  trackerIOValues: null,
};

const TrailsReducer = (state = initialState, action) => {
  let hydrantsObj;
  if (action.type === ActionTypes.FETCH_ALL_TRAILS_SUCCESS || action.type === ActionTypes.FETCH_ALL_TRAILS_W_GEO_SUCCESS) {
    // Build a hydrants obj by pulling the hydrants from our trails route
    hydrantsObj = Object.values(action.payload || {}).map((trail) => (trail.hydrants)).reduce((acc, curr) => ({ ...acc, ...curr }), {});
  }

  switch (action.type) {
    case ActionTypes.FETCH_ALL_TRAILS_SUCCESS:
      return {
        autotrails: state.autotrails,
        autotrailsiovalues: state.autotrailsiovalues,
        trail: state.trail,
        selectedTrailName: state.selectedTrailName,
        selectedAutoTrailName: state.selectedAutoTrailName,
        globalNotes: state.globalNotes,
        trackerIOValues: state.trackerIOValues,
        hydrants: Object.values(hydrantsObj || {}).length > 0 ? { ...hydrantsObj } : {},
        trails: {
          // This reducer looks a bit more complicated because it is designed to override the previous state's features
          // with the payload's features while still leaving the geometry (and other potentially null members) intact
          ...state.trails,
          ...action.payload,
          ...Object.values(action.payload || {}).map((trail) => {
            return {
              ...state.trails?.[trail.id],
              ...trail,
            };
          }).reduce((acc, curr) => {
            acc[curr.id] = curr;
            return acc;
          }, {}),
        },
      };
    case ActionTypes.FETCH_ALL_TRAILS_W_GEO_SUCCESS:
      return {
        ...state,
        trails: {
          ...action.payload,
        },
        hydrants: Object.values(hydrantsObj || {}).length > 0 ? { ...hydrantsObj } : {},
      };
    case ActionTypes.NOTE_ADD_CHANGE_SUCCESS:
      return {
        ...state,
        hydrants: {
          ...state.hydrants,
          [action.payload.hydrantId]: {
            ...state.hydrants[action.payload.hydrantId],
            notes: {
              ...state.hydrants[action.payload.hydrantId].notes,
              [action.payload.id]: {
                ...action.payload,
                user: {
                  ...state.hydrants[action.payload.hydrantId].notes[action.payload.id].user,
                },
              },
            },
          },
        },
        globalNotes: {
          ...state.globalNotes,
          [action.payload.id]: action.payload,
        },
      };
    case ActionTypes.UPDATE_HYDRANT_SUCCESS:
      // console.log({
      //   ...state,
      //   hydrants: {
      //     ...state.hydrants,
      //     [action.payload.id]: {
      //       ...action.payload,
      //       equipment: state.hydrants[action.payload.id].equipment,
      //       notes: state.hydrants[action.payload.id].notes,
      //       trailName: state.hydrants[action.payload.id].trailName,
      //     },
      //   },
      // });
      return {
        ...state,
        hydrants: {
          ...state.hydrants,
          [action.payload.id]: {
            ...action.payload,
            equipment: state.hydrants[action.payload.id].equipment,
            notes: state.hydrants[action.payload.id].notes,
            trailName: state.hydrants[action.payload.id].trailName,
          },
        },
      };
    case ActionTypes.DELETE_HYDRANT_SUCCESS:
      return {
        ...state,
        hydrants: Object.values(state.hydrants || {}).filter((hydrant) => (hydrant.id !== action.payload.id)).reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {}),
      };
    case ActionTypes.FETCH_GLOBAL_NOTES_SUCCESS:
      return {
        ...state,
        globalNotes: action.payload,
      };
    case ActionTypes.FETCH_ALL_AUTOTRAILS_SUCCESS:
      return {
        ...state,
        autotrails: action.payload,
      };
    case ActionTypes.FETCH_ALL_AUTOTRAILS_IO_SUCCESS:
      return {
        ...state,
        autotrailsiovalues: action.payload,
      };
    case ActionTypes.FETCH_AUTOTRAIL_IO_SUCCESS:
      return {
        ...state,
        autotrails: {
          ...state.autotrails,
          [action.payload.trailId]: {
            ...state.autotrails?.[action.payload.trailId],
            [action.payload.key]: action.payload,
          },
        },
      };
    case ActionTypes.ADD_AUTO_TRAIL_IO_VALUE:
      return {
        ...state,
        autotrailsiovalues: {
          ...state.autotrailsiovalues,
          [action.payload.data.id]: action.payload.data,
        },
      };
    case ActionTypes.DELETE_AUTO_TRAIL_IO_VALUE:
      return {
        ...state,
        autotrailsiovalues: Object.values(state.autotrailsiovalues || {}).filter((value) => (value.id !== action.payload.id)).reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {}),
      };
    case ActionTypes.FETCH_TRAIL_SUCCESS:
      return {
        ...state,
        trails: {
          ...state.trails,
          [action.payload.id]: {
            ...state.trails[action.payload.id],
            ...action.payload,
            hydrants: {
              ...state.trails[action.payload.id].hydrants,
            },
          },
        },
      };
    case ActionTypes.FETCH_AUTOTRAIL_SUCCESS:
      return {
        ...state,
        autotrails: {
          ...state.autotrails,
          [action.payload.trailId]: action.payload,
        },
      };
    case ActionTypes.SELECT_TRAIL:
      return { ...state, selectedTrailName: action.payload };
    case ActionTypes.ADD_TRAIL_SUCCESS:
      return {
        ...state,
        equipment: state.trails?.[action.payload.id],
        trail: action.payload,
        trails: {
          ...state.trails,
          [action.payload.id]: {
            ...state.trails?.[action.payload.id],
            ...action.payload,
          },
        },
      };
    case ActionTypes.DELETE_TRAIL_SUCCESS:
      return {
        ...state,
        trail: null,
        trails: {
          ...Object.values(state.trails || {}).filter((item) => item.id !== action.payload.id).reduce((acc, curr) => {
            acc[curr.id] = curr;
            return acc;
          }, {}),
        },
      };
    case ActionTypes.EDIT_TRAIL_SUCCESS:
      return {
        ...state,
        trail: action.payload,
        trails: {
          ...state.trails,
          [action.payload.id]: {
            ...state.trails?.[action.payload.id],
            ...action.payload,
          },
        },
      };
    case ActionTypes.SELECT_AUTO_TRAIL:
      return { ...state, selectedAutoTrailName: action.payload };
    case ActionTypes.FETCH_TRACKER_IOVALUES_SUCCESS:
      return { ...state, trackerIOValues: action.payload };
    case ActionTypes.FETCH_AUTOTRAIL_ERROR:
      return { ...state };
    case ActionTypes.FETCH_TRAILS_ERROR:
      return state;
    default:
      return state;
  }
};

export default TrailsReducer;
