import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function fetchWeatherStation(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/weather/${id}`,
        headers: { Authorization: `Bearer ${token}` },

      });
      dispatch({ type: ActionTypes.FETCH_WEATHER_STATION_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_WEATHER_STATION_ERROR, payload: error });
    }
  };
}

export function fetchAllWeatherStations() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/weather/`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_ALL_WEATHER_STATIONS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_WEATHER_STATION_ERROR, payload: error });
    }
  };
}
