/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Switch, Route, Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NavBarSide from './NavBarSide';
import LoginScreen from '../screens/LoginScreen';
// import ReportScreen from '../screens/ReportScreen';
import TrendScreen from '../screens/TrendScreen';
import SettingsScreen from '../screens/SettingsScreen';
import Overview from '../screens/Overview';
import AspenOverview from '../screens/AspenOverview';
import ButtermilkOverview from '../screens/ButtermilkOverview';
import HighlandsOverview from '../screens/HighlandsOverview';
import SnowmassOverview from '../screens/SnowmassOverview';

const WithNavBars = (props) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'row', userSelect: 'none' }}>
      <NavBarSide float={props.float} />
      {props.children}
    </div>
  );
};

WithNavBars.propTypes = {
  children: PropTypes.node.isRequired,
  float: PropTypes.bool,
};

WithNavBars.defaultProps = {
  float: false,
};

const Routes = (props) => {
  const ProtectedRoute = ({ children, ...rest }) => {
    // const auth = props.auth.authenticated || process.env.NODE_ENV === 'development';
    const auth = props.auth.authenticated;
    return (
      <Route
        {...rest}
        render={({ location }) => (auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        ))}
      />
    );
  };

  ProtectedRoute.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const FallBack = () => {
    return <div>URL Not Found</div>;
  };

  return (
    <Switch>
      <Route exact path="/">
        <LoginScreen />
      </Route>

      <ProtectedRoute exact
        path="/overview"
      >
        <WithNavBars float>
          <Overview />
        </WithNavBars>
      </ProtectedRoute>

      <ProtectedRoute exact
        path="/aspen"
      >
        <WithNavBars float>
          <AspenOverview />
        </WithNavBars>
      </ProtectedRoute>

      <ProtectedRoute exact
        path="/buttermilk"
      >
        <WithNavBars float>
          <ButtermilkOverview />
        </WithNavBars>
      </ProtectedRoute>

      <ProtectedRoute exact
        path="/highlands"
      >
        <WithNavBars float>
          <HighlandsOverview />
        </WithNavBars>
      </ProtectedRoute>

      <ProtectedRoute exact
        path="/snowmass"
      >
        <WithNavBars float>
          <SnowmassOverview />
        </WithNavBars>
      </ProtectedRoute>
      {/* <ProtectedRoute exact
        path="/reports"
      >
        <WithNavBars>
          <ReportScreen />
        </WithNavBars>
      </ProtectedRoute> */}

      <ProtectedRoute exact
        path="/trends"
      >
        <WithNavBars>
          <TrendScreen />
        </WithNavBars>
      </ProtectedRoute>

      <ProtectedRoute exact
        path="/settings"
      >
        <WithNavBars>
          <SettingsScreen />
        </WithNavBars>
      </ProtectedRoute>

      <ProtectedRoute exact>
        <WithNavBars>
          <FallBack />
        </WithNavBars>
      </ProtectedRoute>

    </Switch>
  );
};

Routes.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({}),
    authenticated: PropTypes.bool,
    need2fa: PropTypes.bool,
    error: PropTypes.number,
  }).isRequired,

};

const mapStateToProps = (state) => (
  {
    auth: state.auth,
  }
);

export default connect(mapStateToProps, null)(Routes);
