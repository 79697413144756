import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function fetchAllEquipment() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/equipment/`,
        headers: { Authorization: `Bearer ${token}` },

      });
      dispatch({ type: ActionTypes.FETCH_ALL_EQUIPMENT_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_EQUIPMENT_ERROR, payload: error });
    }
  };
}

export function fetchAllValves() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/equipment/valves`,
        headers: { Authorization: `Bearer ${token}` },

      });
      dispatch({ type: ActionTypes.FETCH_ALL_VALVES_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_EQUIPMENT_ERROR, payload: error });
    }
  };
}

export function fetchEquipment(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/pumps/${id}`,
        headers: { Authorization: `Bearer ${token}` },

      });
      dispatch({ type: ActionTypes.FETCH_EQUIPMENT_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_EQUIPMENT_ERROR, payload: error });
    }
  };
}

export function setIOValue(id, data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'put',
        url: `${ROOT_URL}/iovalues/${id}/value`,
        data,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.FETCH_EQUIPMENT_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_EQUIPMENT_ERROR, payload: error });
      return error?.response;
    }
  };
}
